import api from "@/services/api"
import Vue from "vue"
export default {
    name: 'ResetPassword',
    data () {
      return {
        password: '',
        passwordConfirmation: ''
      }
    },
    methods: {
        async submitForm() {
            const token = this.$route.params.token
            if (this.password !== this.passwordConfirmation) {
                Vue.notify({
                    group: 'data',
                    title: 'Fout opgetreden',
                    text: 'wachtwoorden komen niet overeen',
                    type: 'error'
                });
                return;
              }
            const response = await api.put(`/reset_password`, {password: this.password, token: token})
            console.log(response)
            if (response && response.message) {
                Vue.notify({
                    group: 'data',
                    title: 'Gelukt!',
                    text: 'wachtwoord opnieuw instellen succesvol',
                    type: 'success'
                });
                this.$router.push({ name: 'SignIn' })
            }
        },
        goToHomePage() {
            this.$router.push({ name: 'HomePage' })
        },
    }
}