import api from '@/services/api'
import { mapActions, mapState } from 'vuex'

export default {
    name: 'YouthAdvisorQuestionnaires',
    data() {
        return {
            candidate: {},
            personalQuestionnaires: []
        }
    },
    methods: {
        ...mapActions('candidate', [
            'retrieveQuestionnaires',
            'retrieveQuestionnaireStatuses',
        ]),
        async retrieveCandidate(id) {
            const response = await api.get(`/candidates/${id}`)
            if (response && response.data) return response.data
        },
        back() {
            this.$router.push({ name: 'Youth Advisor Detail', params: { id: this.$route.params.id } })
        },
        async retrievePersonalQuestionnaires(id) {
            const response = await api.get(`/candidate_questionnaires/${id}`)
            if (response && response.data) this.personalQuestionnaires = response.data
        },
        async sendQuestionnaire(questionnaire) {
            const response = await api.post('/candidates_questionnaires', {
                candidate_id: this.candidate.id,
                questionnaire_id: questionnaire.id
            })
            if (response && response.data) {
                const index = this.personalQuestionnaires.findIndex(q => q.questionnaire_id == response.data.questionnaire_id)
                index == -1 ? this.personalQuestionnaires.push(response.data) : this.$set(this.personalQuestionnaires, index, response.data) 
            }
        },
    },
    computed: {
        ...mapState('candidate', [
            'questionnaires',
            'questionnaireStatuses',
        ])
    },
    async created() {
        this.candidate = await this.retrieveCandidate(this.$route.params.id)
        this.retrieveQuestionnaires()
        this.retrieveQuestionnaireStatuses()
        this.retrievePersonalQuestionnaires(this.$route.params.id)
    }
}
