import { mapActions } from "vuex"
import api from '@/services/api'

export default {
    name: 'Account',
    data() {
        return {
            user: {
                email: "",
                first_name: "",
                insertion: "",
                last_name: "",
                birthday: ""
            }
        }
    },
    props: {
        activePill: String,
    },
    methods: {
        ...mapActions('authentication', [
            'signOut'
        ]),
        async retrieveUser() {
            const response = await api.get(`/current_user`)
            if (response && response.data) return response.data
        },
        async updateUser(user) {
            const response = await api.put(`/users/${user.id}`, user)
            if (response && response.data) {
                this.user = response.data
                // this.$swal('Details succesvol bijgewerkt');
            }
        },
        goToDashboard() {
            this.$router.push({name: 'Business Account'})
        },
        goToPasswordChange() {
            this.$router.push({name: 'Business Change Password' })
        },
        goToBusinessInfo() {
            this.$router.push({name: 'Business Account Info'})
        }
    },
    computed: {
        isFormValid() {
            return this.user.email && this.user.first_name && this.user.last_name
        },
        reversedBirthday(){
            if (this.user.birthday === null) {
                return null
            } else {
                const birthday = this.user.birthday.split('-').reverse().join('-');
                this.user.birthday = birthday
                return birthday
            }
        }
    },
    async created() {
        this.user = await this.retrieveUser()
    },
}
