import Vue from 'vue'
import Vuex from 'vuex'

import authentication from './modules/authentication'
import admin from './modules/admin'
import candidate from './modules/candidate'
import youthadvisor from './modules/youthadvisor'
import accountmanager from './modules/accountmanager'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    authentication,
    admin,
    candidate,
    accountmanager,
    youthadvisor
  },
});