import { mapActions } from "vuex"

export default {
    name: 'AdminNewUser',
    data() {
        return {
            user: {
                email: "",
                password: "",
                first_name: "",
                insertion: "",
                last_name: "",
                roles: []
            }
        }
    },
    methods: {
        ...mapActions('admin', [
            'createUser'
        ]),
    },
    computed: {
        isFormValid() {
            return this.user.email && this.user.password && this.user.first_name && this.user.last_name
        }
    }
}
