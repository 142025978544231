import api from '@/services/api'

export default {
    name: 'AccountInfo',
    data() {
        return {
            business: {
                name: "",
                kvk_nummer: ""
            }
        }
    },
    props: {
        activePill: String,
    },
    methods: {
        async retrieveBusiness() {
            const response = await api.get(`/current_business`)
            if (response && response.data) return response.data
        },
        async updateBusiness(business) {
            const response = await api.put(`/businesses/${business.id}`, business)
            if (response && response.data) {
                this.business = response.data
                // this.$swal('Details succesvol bijgewerkt');
            }
        },
        goToDashboard() {
            this.$router.push({name: 'Business Account'})
        },
        goToPasswordChange() {
            this.$router.push({name: 'Business Change Password' })
        },
        goToBusinessInfo() {
            this.$router.push({name: 'Business Account Info'})
        }
    },
    computed: {
        isFormValid() {
            return this.business.name && this.business.kvk_nummer
        },
    },
    async created() {
        this.business = await this.retrieveBusiness()
    },
}
