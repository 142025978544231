import { mapActions } from 'vuex'
import Header from './Header/Header.vue'

export default {
    name: 'Candidate',
    components: {
        Header
    },
    methods: {
        ...mapActions('authentication', [
            'retrieveSignedInStatus',
        ]),
    },
    created() {
        this.retrieveSignedInStatus()
    },
    updated() {
        this.retrieveSignedInStatus()
    },
}