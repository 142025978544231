import api from "@/services/api"

export default {
    name: 'CandidateQuestionnaire',
    data() {
        return {
            questionnaire: {},
            currentQuestionIndex: 0, // Current question index
            possibleAnswers: 
            {
                "Niet belangrijk": "1",
                "Nauwelijks belangrijk": "2",
                "Redelijk belangrijk": "3",
                "Belangrijk": "4",
                "Heel erg belangrijk": "5",
            },
            selectedAnswer: null
        }
    },
    methods: {
        async retrieveQuestionnaire(canidateQuestionnaireId) { 
            const response = await api.get(`/candidate_questionnaire/${canidateQuestionnaireId}`)
            if (response && response.data) return response.data
        },
        nextQuestion() {
            this.sendAnswer()
        },
        selectAnswer(value) {
            this.selectedAnswer = value;
        },
        async sendAnswer() {
            const response = await api.post(`/candidates_questionnaires/${this.questionnaire.id}`, {
                answer: this.answer
            })
            if (response && response.data) {
                this.selectedAnswer = null
                if (this.currentQuestionIndex < this.filteredQuestionsAndAnswers.length - 1) {
                    this.currentQuestionIndex++ 
                } else {
                    if (this.questionnaire.name === 'Kennis maken') {
                        this.$router.push({ name: 'HistoricalJobs'})
                    } else {
                        this.$router.replace({ name: 'Candidate Questionnaires' })
                    }
                }
            }
        },
        back() {
            this.$router.push({ name: 'Candidate Dashboard' })
        }
    },
    computed: {
        answer() {
            const question = this.filteredQuestionsAndAnswers[this.currentQuestionIndex]
            return {question_id: question.id, answer: this.selectedAnswer }
        },
        validAnswer() {
            return this.selectedAnswer !== null;
        },
        filteredQuestionsAndAnswers() {
            return this.questionnaire.questions_and_answers.filter((item) => item.answer === null);
        }
    },
    async created() {
        this.questionnaire = await this.retrieveQuestionnaire(this.$route.params.id)
        if (this.questionnaire.questionnaire_id == 1) {
            this.possibleAnswers = {
                "Helemaal niet waar": "1",
                "Nauwelijks waar": "2",
                "Redelijk waar": "3",
                "Helemaal waar": "4",
            }
        }
    }
}
