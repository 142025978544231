import api from '@/services/api'
import router from '../../router'
import Vue from "vue"

const state = () => ({
    signedIn: false,
    roles: {},
    error: ''
})

const mutations = {
    setSignedIn(state, status) {
        state.signedIn = status
    },
    setRoles(state, roles) {
        state.roles = roles
    },
}

const actions = {
    retrieveSignedInStatus({commit}) {
        const signedIn = localStorage.getItem('signedIn')
        if (signedIn) {
            const roles = JSON.parse(localStorage.getItem('roles'))
            commit('setSignedIn', signedIn)
            commit('setRoles', roles)
            return true;
        } else {
            router.replace('/login').catch(()=>{})
            return false;
        }
    },
    async signin({dispatch}, data) {
        const response = await (await api.post('/signin', { email: data.email, password: data.password }))
        if (response && response.csrf) {
            dispatch('signinSuccessful', response)
        } else {
            dispatch('signinFailed', response)
        }
    },
    async refreshToken({state, dispatch}) {
        if (!state.signedIn) {
            router.replace('/login').catch(()=>{})
            return;
        }
        const response = await (await api.post('/refresh', {}, false))
        if (response && response.csrf) {
            localStorage.setItem('csrf', response.csrf)
            dispatch('retrieveSignedInStatus')
        } else {
            dispatch('signinFailed', response)
            router.replace('/login').catch(()=>{})
        }
    },
    signinSuccessful({dispatch}, response) {
        if (response.roles) {
            localStorage.setItem('csrf', response.csrf)
            localStorage.setItem('signedIn', true)
            localStorage.setItem('roles', JSON.stringify(response.roles))
            
            if (Object.keys(response.roles).length > 1) {
                console.log('Multiple roles')
                router.replace('/rol').catch(()=>{})
            } else if (response.roles['admin']) {
                console.log('Admin')
                router.replace('/admin').catch(()=>{})
            } else if (response.roles['candidate']) {
                if (response.sign_in_count == 1) {
                    router.replace('/kandidaat/account/change-password')
                } else if (response.sign_in_count == 2 && response.business_id == null) {
                    router.replace('/kandidaat/onboarding').catch(()=>{})
                } else {
                    router.replace('/kandidaat').catch(()=>{})
                }
                console.log('Candidate')
            } else if (response.roles['business']) {
                console.log('Business')
                router.replace('/bedrijf').catch(()=>{})
            } else if (response.roles['youth_advisor']) {
                console.log('Youth advisor')
                router.replace('/dashboard').catch(()=>{})
            } else if (response.roles['account_manager']) {
                console.log('Account manager')
                router.replace('/account-manager').catch(()=>{})
            } else {
                Vue.notify({
                    group: 'data',
                    title: 'Fout opgetreden',
                    text: `Er is geen rol toegewezen aan deze gebruiker`,
                    type: 'error'
                });
                localStorage.removeItem('csrf')
                localStorage.removeItem('signedIn')
                localStorage.removeItem('roles')
            }
        } else {
            Vue.notify({
                group: 'data',
                title: 'Fout opgetreden',
                text: `Er is geen rol toegewezen aan deze gebruiker`,
                type: 'error'
            });
        }
        dispatch('retrieveSignedInStatus')
    },
    signinFailed({dispatch}) {
        localStorage.removeItem('csrf')
        localStorage.removeItem('signedIn')
        localStorage.removeItem('roles')
        dispatch('retrieveSignedInStatus')
    },
    async signOut({dispatch}) {
        const response = await api.delete('/signin')
        if (!response.error) {
            localStorage.removeItem('csrf')
            localStorage.removeItem('signedIn')
            localStorage.removeItem('roles')
            dispatch('retrieveSignedInStatus')
        } else {
            if (response.error == "Not authorized") {
                localStorage.removeItem('csrf')
                localStorage.removeItem('signedIn')
                localStorage.removeItem('roles')
                dispatch('retrieveSignedInStatus')
            } else {
                console.log(response)
                alert('Cannot sign out')
            }
        }
    },
    goToSignIn() {
        router.replace('/login').catch(()=>{})
    }
}

const getters = {
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}