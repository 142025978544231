export default {
    name: 'TogglePills',
    props: {
        activePill: String,
    },
    methods: {
        goToDashboard() {
            this.$router.push({name: 'Candidate Dashboard'})
        },
        goToJobOffers() {
            this.$router.push({path: `/kandidaat/job-offers` })
        },
        goToPlanning() {
            this.$router.push({name: 'Candidate Planning'})
        }
    }
}