import api from '@/services/api'
export default {
    name: 'AuthorizationRequests',
    data() {
        return {
            requests: [
                {
                    status: "",
                    user: {
                        name: "",
                        avatar: null
                    }
                }
            ]
        }
    },
    methods: {
        async retrieveRequests() {
            const response = await api.get('/business/authorization_requests')
            if (response && response.data) return response.data;
        },
        async authorizeRequest(id) {
            const response = await api.put(`/authorization_request/${id}`, {status: 'Authorized'})
            if (response && response.data) {
                this.requests = await this.retrieveRequests() 
            }
        }
    },
    async created() {
        this.requests = await this.retrieveRequests()
    }
}
