import api from '@/services/api'
import AccountTogglePills from '../AccountTogglePills/AccountTogglePills.vue'
import { mapActions } from "vuex"

export default {
    name: 'CandidateAvatar',
    components: {
        AccountTogglePills
    },
    data() {
        return {
            candidate: {}
        }
    },
    methods: {
        ...mapActions('authentication', [
            'signOut'
        ]),
        async retrieveCandidate() {
            const response = await api.get(`/current_candidate`)
            if (response && response.data) return response.data
        },
        back() {
            this.$router.push({ name: 'CandidateDashboard' })
        },
        uploadFile(event) {
            this.candidate.user.avatar = event.target.files[0];
        },
        url() {
            return process.env.VUE_APP_API_URL
        },
        async updateAvatar() {
            var headers = new Headers();
            headers.append("X-CSRF-Token", localStorage.getItem('csrf'));
            // headers.append('Content-Type', 'multipart/form-data'); Browser sets this for me.
            headers.append('Accept', 'application/json');

            let formData = new FormData();
            formData.append( 'method', 'PUT' );
            formData.append('file', this.candidate.user.avatar)

            const response = await (await fetch(this.url() + '/update_avatar', {
                method: 'PUT',
                credentials: "include",
                headers: headers,
                body: formData
            }))

            if (response && response.ok) {
                this.candidate = await this.retrieveCandidate();
                this.$router.replace({ name: 'Candidate Account' })
            }
        },
    },
    computed: {
        isFormValid() {
            return this.candidate.user.avatar
        }
    },
    async created() {
        this.candidate = await this.retrieveCandidate()
    },
}
