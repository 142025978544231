import api from "@/services/api"
import TogglePills from '../TogglePills/TogglePills.vue'
export default {
    name: 'JobOffers',
    components: {
        TogglePills
    },
    data() {
        return {
            matchedBusinesses: [],
            isLoading: false
        }
    },
    methods: {
        async retrieveMatchedBusinesses() {
            this.isLoading = true
            const response = await api.get(`/matched_businesses`)
            if (response && response.data){
                this.isLoading = false
                return response.data
            }
        },
        goToJobOffer(jobOfferId) {
            this.$router.push({path: `/kandidaat/job-offers/${jobOfferId}`, params: { id: jobOfferId } })
        },
    },
    async created() {
        this.matchedBusinesses = await this.retrieveMatchedBusinesses()
    }
}