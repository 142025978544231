import api from '@/services/api'
import { mapActions } from "vuex"

export default {
    name: 'HistoricalJobs',
    data() {
        return {
            candidate: {},
            search: '',
            historicalJobs: [],
            similarJobs: [],
            competences: [],
            loadingSimilar: false,
            professions: [],
            businesses: []
        }
    },
    methods: {
        ...mapActions('authentication', [
            'signOut'
        ]),
        async retrieveCandidate() {
            const response = await api.get(`/current_candidate`)
            if (response && response.data) return response.data
        },
        async retrieveHistoricalJobs(id) {
            const response = await api.get(`/historical_jobs/${id}`)
            if (response && response.data) return response.data
        },
        selectProfession(job) {
            const index = this.historicalJobs.indexOf(job)
            if (index == -1) {
                if (this.historicalJobs.length >= 3) {
                    console.log("Maximaal 3 beroepen")
                } else {
                    job['candidate_id'] = this.candidate.id
                    job['profession_id'] = job.id
                    this.historicalJobs.push(job)
                }
            } else {
                this.historicalJobs.splice(index, 1)
            }
        },
        removeHistoricalJob(job) {
            const index = this.historicalJobs.indexOf(job)
            this.historicalJobs.splice(index, 1)
        },
        async postHistoricalJobs() {
            const response = await api.post('/historical_jobs', {jobs: this.historicalJobs, candidate_id: this.candidate.id})
            if (response && response.data) {
                this.historicalJobs = response.data
                this.$router.push({ name: 'Candidate Dashboard' })
            } 
        },
        async retrieveProfessionsBySearch(search) {
            const response = await api.post('/professions', {search: search})
            if (response && response.data) return response.data
        },
        async mapProfessionsBySearch(search) {
            this.professions = await this.retrieveProfessionsBySearch(search)
        }
    },
    async created() {
        this.professions = await this.retrieveProfessionsBySearch(this.search)
        this.candidate = await this.retrieveCandidate()
        this.historicalJobs = await this.retrieveHistoricalJobs(this.candidate.id)
    },
}
