import api from '@/services/api'
export default {
    name: 'JobOffers',
    data() {
        return {
            jobOffers: [],
            isLoading: false
        }
    },
    methods: {
        async retrieveJobOffers() {
            this.isLoading = true
            const response = await api.get(`/account_manager/job_offers`)
            if (response && response.data){
                this.isLoading = false
                return response.data
            }
        },
        goToJobOffer(id) {
            this.$router.push({ name: 'JobOfferEdit', params: { id: id } })
        }
    },
    async created() {
        this.jobOffers = await this.retrieveJobOffers()
    }
}
