import api from "@/services/api"
import moment from 'moment';
import 'moment/locale/nl'  // without this line it didn't work
moment.locale('nl')
export default {
    name: 'TimeSlots',
    data() {
        return {
            openTimeSlots: {},
            selectedTimeSlot: null
        }
    },
    methods: {
        async retrieveOpenTimeSlots() {
            const response = await api.get(`/open_time_slots`);
            if (response) return response;
        },
        toggleBookedCandidates(key) {
            this.selectedTimeSlot = (this.selectedTimeSlot === key) ? null : key;
        }
    },
    filters: {
        fullDate: function (date) {
            return moment(date).format('dddd DD MMMM')
          },
        time: function (date) {
            return moment(date).format('HH:mm')
        },
    },
    async created() {
        this.openTimeSlots = await this.retrieveOpenTimeSlots();
    }
}