import api from "@/services/api"
export default {
    name: 'BusinessDashboard',
    data() {
        return {
            questionnaires: [],
            showQuestionnaires: false,
            displayIntro: true,
            isLoading: false,
            dashboardData: {},
            user: {}
        }
    },
    methods: {
        async getQuestionnaires() {
            this.isLoading = true
           const response = await api.get(`/business_questionnaires`)
            if (response && response.data) {
                this.isLoading = false
                return response.data.questionnaires;
            }
        },
        goToQuestionnaire(id) {
            this.$router.push({ name: 'Business Questionnaire', params: { id: id } })
        },
        goToOpenTimeSlots() {
            this.$router.push({ name: 'TimeSlots' })
        },
        goToEmployees() {
            this.$router.push({ name: 'Employees' })
        },
        jobOffers() {
            this.$router.push({ name: 'JobOffers' })
        },
        employees() {
            this.$router.push({ name: 'Employees' })
        },
        completedQuestionnaires() {
            if (this.questionnaires.incompleet.length >= 1) {
                this.showQuestionnaires = true
            }
        },
        showIntro() {
            if (this.questionnaires.compleet.length > 0) {
              this.hideIntro()  
            }
        },
        hideIntro() {
            this.displayIntro = false
        },
        async retrieveDashboardData() {
            this.isLoading = true
            const response = await api.get('/business/dashboard_data')
            if (response) {
                this.isLoading = false
                return response
            }
        },
        async retrieveUser() {
            const response = await api.get(`/current_user`)
            if (response && response.data) return response.data
        },
        goToAuthorizationRequests() {
            this.$router.push({ name: 'AuthorizationRequests' })
        },
    },
    async created() {
        this.questionnaires = await this.getQuestionnaires()
        this.completedQuestionnaires();
        this.showIntro();
        this.dashboardData = await this.retrieveDashboardData()
        this.user = await this.retrieveUser()
    },
}
