import api from "@/services/api"

export default {
    name: 'BusinessQuestionnaire',
    data() {
        return {
            questionnaire: {},
            currentQuestionIndex: 0, // Current question index
            possibleAnswers: 
            {
                "Niet belangrijk": "1",
                "Nauwelijks belangrijk": "2",
                "Redelijk belangrijk": "3",
                "Belangrijk": "4",
                "Heel erg belangrijk": "5",
            },
            selectedAnswer: null
        }
    },
    methods: {
        async retrieveQuestionnaire(id) {
            const response = await api.get(`/business_questionnaire/${id}`)
            if (response && response.data) return response.data
        },
        nextQuestion() {
            this.sendAnswer(); 
        },
        selectAnswer(value) {
            this.selectedAnswer = value;
        },
        async sendAnswer() {
            const response = await api.post(`/business_questionnaire`, {
                answer: this.answer
            })
            if (response && response.data) {
                this.selectedAnswer = null
                if (this.currentQuestionIndex < this.filteredQuestionsAndAnswers.length - 1) {
                    this.currentQuestionIndex++ 
                } else {
                    this.$router.replace({ name: 'Business Dashboard' }) 
                }
            }
        },
        back() {
            this.$router.push({ name: 'Business Dashboard' })
        }
    },
    computed: {
        answer() {
            const question = this.filteredQuestionsAndAnswers[this.currentQuestionIndex]
            return {business_question_id: question.id, answer: this.selectedAnswer }
        },
        validAnswer() {
            return this.selectedAnswer !== null;
        },
        filteredQuestionsAndAnswers() {
            return this.questionnaire.questions_and_answers.filter((item) => item.answer === null);
        }
    },
    async created() {
        this.questionnaire = await this.retrieveQuestionnaire(this.$route.params.id)
    }
}
