import api from '@/services/api'
export default {
    name: 'CandidateOnboarding',
    data() {
        return {
            motivationQuestionnaire: {} 
        }
    },
    methods: {
        goToMotivationQuestionnaire() {
            this.$router.replace(`/kandidaat/vragenlijst/${this.motivationQuestionnaire.id}`)
        },
        async retrieveMotivationQuestionnaire() {
            const response = await api.get('/candidate/motivation_questionnaire')
            if (response) return response
        },
        goToDashboard() {
            this.$router.push({ name: 'Candidate Dashboard' })
        }
    },
    async created() {
        this.motivationQuestionnaire = await this.retrieveMotivationQuestionnaire()
    }
}