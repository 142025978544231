import { mapActions, mapState } from "vuex"

export default {
    name: 'AdminDashboard',
    computed: {
        ...mapState('admin', [
            'users'
        ])
    },
    methods: {
        ...mapActions('admin', [
            'retrieveUsers',
            'deleteUser'
        ]),
        add() {
            this.$router.push({ name: 'Admin New'})
        },
        edit(id) {
            this.$router.push({ name: 'Admin Detail', params: { id: id } })
        },
    },
    created() {
        this.retrieveUsers()
    }
}
