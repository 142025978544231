import Header from './Header/Header.vue'
import { mapActions, mapState } from 'vuex'

export default {
    name: 'YouthAdvisor',
    components: {
        Header
    },
    computed: {
        ...mapState('authentication', [
            'signedIn',
        ])
    },
    methods: {
        ...mapActions('authentication', [
            'retrieveSignedInStatus',
            'goToSignIn'
        ]),
    },
    created() {
        this.retrieveSignedInStatus()
    },
    updated() {
        this.retrieveSignedInStatus()
    },
}
