import api from "@/services/api"
import { mapActions, mapState } from "vuex"

export default {
    name: 'JobOfferNew',
    data() {
        return {
            jobOffer: {
                profession_id: "",
                description: "",
                business_id: "",
            },
            search: "",
            showLocation: false,
            locale: 'nl',
            labels: {
                nl: {
                    labelHours: 'Uur',
                    labelMinutes: 'minuten',
                    labelSeconds: 'seconden',
                    labelIncrement: 'Toename',
                    labelDecrement: 'Verminderen',
                    labelSelected: 'Geselecteerde tijd',
                    labelNoTimeSelected: 'Geen tijd geselecteerd',
                    labelCloseButton: 'Dichtbij'
                }
            }
        }
    },
    methods: {
        ...mapActions('youthadvisor', [
            'retrieveProfessionsBySearch'
        ]),
        async createJobOffer() {
            const response = await api.post('/job_offers', this.jobOffer)
            if (response && response.data) {
                this.$router.push({ name: 'JobOfferEdit', params: { id: response.data.id } })
            }
        },
    },
    computed: {
        isFormValid() {
            return this.jobOffer.description && this.jobOffer.profession_id
        },
        ...mapState('youthadvisor', [
            'professions'
        ])
    },
    async created() {
        this.retrieveProfessionsBySearch("")
        this.jobOffer.business_id = this.$route.params.id
    }
}
