import api from "@/services/api"
import TogglePills from '../TogglePills/TogglePills.vue'
import OnboardingDashboard from '../OnboardingDashboard/OnboardingDashboard.vue';

export default {
    name: 'CandidateDashboard',
    components: {
        TogglePills,
        OnboardingDashboard
    },
    data() {
        return {
            dashboardData: {},
            isLoading: false
        }
    },
    methods: {
        async retrieveDashboardData() {
            this.isLoading = true
            const response =  await api.get('/candidate/dashboard')
            if (response) {
                this.isLoading = false
                return response
            }
        },
        goToPlanning() {
            this.$router.push({name: 'Candidate Planning'})
        }
    },
    async created() {
        this.dashboardData = await this.retrieveDashboardData()
    }
}
