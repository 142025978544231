import { mapActions, mapState } from 'vuex'

export default {
    name: 'Authentication',
    computed: {
        ...mapState('authentication', [
            'signedIn',
            'roles'
        ]),
    },
    methods: {
        ...mapActions('authentication', [
            'retrieveSignedInStatus',
            'goToSignIn'
        ]),
        goToPage(role) {
            if (role == 'admin') {
                this.$router.replace('/admin').catch(()=>{})
            } else if (role == 'candidate') {
                this.$router.replace('/kandidaat').catch(()=>{})
            } else if (role == 'business') {
                this.$router.replace('/bedrijf').catch(()=>{})
            } else if (role == 'youth_advisor') {
                this.$router.replace('/dashboard').catch(()=>{})
            } else if (role == 'account_manager') {
                this.$router.replace('/account-manager').catch(()=>{})
            } else {
                console.log("Geen pagina gevonden voor deze rol")
            }
        }
    },
    created() {
        this.retrieveSignedInStatus()
    },
    updated() {
        this.retrieveSignedInStatus()
    },
}
