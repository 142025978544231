import api from "@/services/api"
export default {
    name: 'BusinessEmployees',
    data() {
        return {
            employees: [],
        }
    },
    methods: {
        async retrieveEmployees() {
            const response = await api.get(`/employees`)
            if (response && response.data) return response.data
        },
        back() {
            this.$router.replace({ name: 'Business Dashboard' })
        },
        add() {
            this.$router.replace({ name: 'EmployeeNew'})
        },
    },
    async created() {
        this.employees = await this.retrieveEmployees()
    },
}
