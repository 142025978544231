import { mapActions } from "vuex" 

export default {
    name: 'NewBusiness',
    data() {
        return {
            business: {
                name: "",
                kvk_nummer: "",
                email: "",
                first_name: "",
                insertion: "",
                last_name: "",
                hr: {},
                operations: {},
            },
            toggle: false,
            toggle_two: false
        }
    },
    methods: {
        ...mapActions('accountmanager', [
            'createBusiness'
        ]),
        back() {
            this.$router.push({ name: 'Businesses' })
        },
        showSecondaryForm() {
            this.toggle = !this.toggle
            if(this.toggle) {
                this.$nextTick(() => {
                    this.$refs["hr"].scrollIntoView({ behavior: "smooth" })
                })
            }
          },
        showTertiaryForm() {
            this.toggle_two = !this.toggle_two
            if(this.toggle_two) {
                this.$nextTick(() => {
                    this.$refs["manager"].scrollIntoView({ behavior: "smooth" })
                })
            }
        },
    },
    computed: {
        isFormValid() {
            return this.business.name && this.business.email && this.business.first_name && this.business.last_name
        }
    }
}
