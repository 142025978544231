import api from "@/services/api"
import moment from 'moment';
import 'moment/locale/es'  // without this line it didn't work
moment.locale('nl')
import TogglePills from '../TogglePills/TogglePills.vue'
export default {
    name: 'Planning',
    components: {
        TogglePills
    },
    data() {
        return {
            bookedTimeSlots: [],
            date: new Date(),
            timeSlot: {},
            bookedSlot: {},
            toggle: false,
            startX: 0,
            startY: 0,
            deltaX: 0,
            deltaY: 0,
            isLoading: false,
        }
    },
    methods: {
        async getBookedTimeSlots() {
            this.isLoading = true
            const response = await api.get(`/candidate/booked_timeslots?date=${this.date}`)
            if (response && response.data) {
              this.isLoading = false
              return response.data
            }
        },
        async showEditModal(event) {
            this.$refs['modal-2'].show()
            const id = event.target.id
            const response = await api.get(`/time_slots/${id}`);
            if (response & response.id) return response;
            this.timeSlot = response;
        },
        showCancelForm() {
            this.toggle = !this.toggle
        },
        async cancelBooking() {
            const response = await api.put(`/time_slot_bookings/${this.timeSlot.id}/cancel_booking`, this.bookedSlot)
            if (response) {
                this.bookedTimeSlots = await this.getBookedTimeSlots()
            }
            this.$refs['modal-2'].hide()
        },
        async nextDay() {
            const newDate = this.date;
            newDate.setDate(newDate.getDate() + 1)
            this.date = newDate;
            this.bookedTimeSlots = await this.getBookedTimeSlots()
        },
        async previousDay() {
            const newDate = this.date;
            newDate.setDate(newDate.getDate() - 1)
            this.date = newDate;
            this.bookedTimeSlots = await this.getBookedTimeSlots()
        },
        startSwipe(event) {
            this.startX = event.touches[0].clientX;
            this.startY = event.touches[0].clientY;
          },
        swipe(event) {
            this.deltaX = event.touches[0].clientX - this.startX;
            this.deltaY = event.touches[0].clientY - this.startY;
        },
        endSwipe() {
            if (Math.abs(this.deltaX) > Math.abs(this.deltaY)) {
              if (this.deltaX > 0) {
                this.previousDay();
              } else {
                this.nextDay();
              }
            }
            this.deltaX = 0;
            this.deltaY = 0;
        },
    },
    filters: {
        day: function (date) {
          return moment(date).format('dddd');
        },
        date: function (date) {
          return moment(date).format('DD MMM')
        },
        time: function (date) {
          return moment(date).format('HH:mm')
        },
        fullDate: function (date) {
          return moment(date).format('dddd DD MMMM')
        }
      },
    async created() {
        this.bookedTimeSlots = await this.getBookedTimeSlots()
        console.log(this.bookedTimeSlots);
    }
}