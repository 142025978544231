import api from "@/services/api"

export default {
    name: 'JobOffers',
    data() {
        return {
            jobOffers: [],
            accountManager: {},
            isLoading: false
        }
    },
    methods: {
        async retrieveJobOffers() {
            this.isLoading = true
            const response = await api.get(`/job_offers`)
            if (response && response.data) {
                this.isLoading = false
                return response.data
            }
        },
        add() {
            this.$router.push({ name: 'JobOfferNew'})
        },
        edit(id) {
            this.$router.push({ name: 'JobOfferEdit', params: { id: id } })
        },
        async deleteJobOffer(id) {
            const response = await api.delete(`/job_offers/${id}`)
            if (response && response.data) {
                const index = this.jobOffers.findIndex(c => c.id == response.data.id);
                if (index != -1) this.jobOffers.splice(index, 1)
            }
        },
        back() {
            this.$router.replace({ name: 'Business Dashboard' })
        },
        async deactivateVacancy(event) {
            const id = event.target.id
            if(confirm("Wil je echt deactiveren?")) {
              const response = await api.get(`/job_offers/${id}/deactivate`)
              if (response && response.data) {
                  this.jobOffers = await this.retrieveJobOffers()
              }
            }
        },
    },
    async created() {
        this.jobOffers = await this.retrieveJobOffers()
    },
}
