import Header from './Header/Header.vue'
import { mapActions } from 'vuex'
import api from "@/services/api";

export default {
    name: 'Business',
    data() {
        return {
            account_manager: {},
            activeLink: '',
            user: {}
        }
    },
    components: {
        Header
    },
    methods: {
        ...mapActions('authentication', [
            'retrieveSignedInStatus',
        ]),
        ...mapActions('authentication', [
            'signOut'
        ]),
        goToDashboard() {
            this.$router.push({name: 'Business Dashboard'})
            this.activeLink = 'dashboard';
        },
        goToJobOffers() {
            this.$router.push({name: 'JobOffers'})
            this.activeLink = 'jobOffers';
        },
        goToAccountManagerDashboard() {
            this.$router.push({name: 'Account Manager Dashboard'})
            this.activeLink = 'dashboard';
        },
        goToAccountManagerBusinesses() {
            this.$router.push({name: 'Businesses'})
            this.activeLink = 'businesses';
        },
        goToAccountManagerJobOffers() {
            this.$router.push({name: 'Account Manager Job Offers'})
            this.activeLink = 'jobOffers';
        },
        async retrieveUser() {
            const response = await api.get(`/current_user`)
            if (response && response.data) return response.data
        },
    },
    async created() {
        this.retrieveSignedInStatus()
        this.user = await this.retrieveUser()
        this.accountManager = this.user.account_manager
    },
    updated() {
        this.retrieveSignedInStatus()
    },
}
