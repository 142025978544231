import api from '@/services/api'
import router from '../../router'

const state = () => ({
    businesses: [],
})

const getters = {
}

const mutations = {
    setBusinesses(state, businesses) {
        state.businesses = businesses
    },
    deleteBusiness(state, business) {
        const index = state.businesses.findIndex(c => c.id == business.id);
        if (index != -1) state.businesses.splice(index, 1)
    },
    updateBusiness(state, business) {
        const index = state.businesses.indexOf(business);
        if (index) state.businesses[index] = business
    },
    addBusiness(state, business) {
        state.businesses.push(business)
    },
}

const actions = {
    async retrieveBusinesses({commit}) {
        const response = await api.get('/businesses')
        if (response && response.data) commit('setBusinesses', response.data)
    },
    async deleteBusiness({commit}, id) {
        const response = await api.delete(`/businesses/${id}`)
        if (response && response.data) commit('deleteBusiness', response.data)
    },
    async updateBusiness({commit}, business) {
        const response = await api.put(`/businesses/${business.id}`, business)
        if (response && response.data) {
            commit('updateBusiness', response.data)
            router.push({ name: 'Businesses' })
        }
    },
    async createBusiness({commit}, business) {
        const response = await api.post('/businesses', business)
        if (response && response.data) {
            commit('addBusiness', response.data)
            router.push({ name: 'Businesses' })
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}