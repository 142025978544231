import api from '@/services/api'
import Vue from "vue"
import AccountTogglePills from '../AccountTogglePills/AccountTogglePills.vue'
import { mapActions } from "vuex"

export default {
    name: 'ChangePassword',
    components: {
        AccountTogglePills
    },
    data() {
        return {
            candidate: {
                user: {
                    first_name: "",
                    current_password: "",
                    new_password: "",
                    password_confirmation: "",
                    avatar: null
                }
            }
        }
    },
    methods: {
        ...mapActions('authentication', [
            'signOut'
        ]),
        async retrieveCandidate() {
            const response = await api.get(`/current_candidate`)
            if (response && response.data) return response.data
        },
        async updateCandidatePassword(candidate) {
            const response = await api.put(`/candidates/${candidate.id}/change_password`, candidate)
            if (response) {
                Vue.notify({
                    group: 'data',
                    title: 'Gelukt!',
                    text: 'wachtwoord opnieuw instellen succesvol',
                    type: 'success'
                });
                this.signOut();
            }

        },
        goToUpdateDetails() {
            this.$router.push({ name: 'Candidate Account' })
        },
    },
    computed: {
        isFormValid() {
            return this.candidate.user.current_password && this.candidate.user.new_password && this.candidate.user.password_confirmation
        }
    },
    async created() {
        this.candidate = await this.retrieveCandidate()
    },
}
