import api from '@/services/api'

const state = () => ({
    questionnaires: [],
    questionnaireStatuses: [],
})

const getters = {
}

const mutations = {
    setQuestionnaires(state, questionnaires) {
        state.questionnaires = questionnaires
    },
    setQuestionnaireStatuses(state, statuses) {
        state.questionnaireStatuses = statuses
    }
}

const actions = {
    async retrieveQuestionnaires({commit}) {
        const response = await api.get('/questionnaires')
        if (response && response.data) commit('setQuestionnaires', response.data)
    },
    async retrieveQuestionnaireStatuses({commit}) {
        const response = await api.get('/questionnaire_statuses')
        if (response && response.data) commit('setQuestionnaireStatuses', response.data)
    },
    
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}