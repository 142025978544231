import api from "@/services/api"
export default {
    name: 'SignUp',
    data () {
      return {
        candidate: {
          fullname: '',
          email: ''
        },
        showMessage: false,
        isChecked: false
      }
    },
    methods: {
      async registerCandidate() {
        const response = await api.post(`/candidates/register_candidate`, this.candidate)
        if (response) {
          this.showMessage = true
        }
      },
      goToHomePage() {
        this.$router.push({ name: 'HomePage' })
      }
    },
    computed: {
      isFormValid() {
          return this.candidate.fullname && this.candidate.email && this.isChecked
      },
    }
}