// import api from "@/services/api"

export default {
    name: 'BusinessEmployeeNew',
    data() {
        return {
            file: null,
            user: {},
            showSuccessMessage: false
        }
    },
    methods: {
        back() {
            this.$router.push({ name: 'JobOfferEdit', params: { id: this.$route.params.id } })
        },
        uploadFile(event) {
            this.file = event.target.files[0];
        },
        url() {
            return process.env.VUE_APP_API_URL
        },
        async migrateEmployees() {
            var headers = new Headers();
            headers.append("X-CSRF-Token", localStorage.getItem('csrf'));
            // headers.append('Content-Type', 'multipart/form-data'); Browser sets this for me.
            headers.append('Accept', 'application/json');

            let formData = new FormData();
            formData.append( 'method', 'PUT' );
            formData.append('file', this.file)
            formData.append('jobId', this.$route.params.id)

            const response = await (await fetch(this.url() + '/migrate_employees', {
                method: 'PUT',
                credentials: "include",
                headers: headers,
                body: formData
            }))

            // const response = await api.put('/migrate_employees', {file: this.file})
            if (response) {
                this.showSuccessMessage = true;
            }
        },
    }
}
