import { mapActions } from 'vuex'
import Header from './Header/Header.vue'
import api from "@/services/api";

export default {
    name: 'AccountManager',
    data() {
        return {
          user: {},
          activeLink: 'dashboard'
        };
      },
    components: {
        Header
    },
    methods: {
        ...mapActions('authentication', [
            'retrieveSignedInStatus',
        ]),
        ...mapActions('authentication', [
            'signOut'
        ]),
        goToBusinesses() {
            this.$router.push({name: 'Businesses'})
            this.activeLink = 'businesses';
        },
        goToDashboard() {
            this.$router.push({name: 'Account Manager Dashboard'})
            this.activeLink = 'dashboard';
        },
        goToJobOffers() {
            this.$router.push({name: 'Account Manager Job Offers'})
            this.activeLink = 'jobOffers';
        },
        async retrieveUser() {
            const response = await api.get(`/current_user`)
            if (response && response.data) return response.data
        },
    },
    async created() {
        this.retrieveSignedInStatus()
        this.user = await this.retrieveUser()
    },
    updated() {
        this.retrieveSignedInStatus()
    },
}
