import api from '@/services/api'
import { mapActions, mapState } from 'vuex'

export default {
    name: 'YouthAdvisorDetail',
    data() {
        return {
            candidate: {},
            search: '',
            proposedJobs: [],
            proposedJobStatuses: [],
            jobOffers: []
        }
    },
    methods: {
        ...mapActions('youthadvisor', [
            'retrieveProfessionsBySearch'
        ]),
        async retrieveCandidate(id) {
            const response = await api.get(`/candidates/${id}`)
            if (response && response.data) return response.data
        },
        edit() {
            this.$router.push({ name: 'Youth Advisor Edit', params: { id: this.$route.params.id } })
        },
        jobs() {
            this.$router.push({ name: 'Youth Advisor Jobs', params: { id: this.$route.params.id } })
        },
        businesses() {
            this.$router.push({ name: 'Youth Advisor Businesses', params: { id: this.$route.params.id } })
        },
        questionnaires() {
            this.$router.push({ name: 'Youth Advisor Questionnaires', params: { id: this.$route.params.id } })
        },
        async retrieveProposedJobs(id) {
            const response = await api.get(`/proposed_jobs/${id}`)
            if (response && response.data) return response.data
        },
        async retrieveJobOffers(id) {
            const response = await api.get(`/job_offers_ya/${id}`)
            if (response && response.data) return response.data
        },
        async retrieveProposedJobStatuses() {
            const response = await api.get(`/proposed_job_statuses`)
            if (response && response.data) return response.data
        },
        selectProfession(job) {
            const index = this.proposedJobs.indexOf(job)
            if (index == -1) {
                if (this.proposedJobs.length >= 20) {
                    console.log("Maximaal 20 beroepen")
                } else {
                    const addStatus = this.proposedJobStatuses.find(status => status.name == "Toegevoegd")
                    job['candidate_id'] = this.candidate.id
                    job['profession_id'] = job.id
                    job['proposed_job_status_id'] = addStatus ? addStatus.id : null
                    this.proposedJobs.push(job)
                }
            } else {
                this.proposedJobs.splice(index, 1)
            }
        },
        removeProposedJob(job) {
            const index = this.proposedJobs.indexOf(job)
            this.proposedJobs.splice(index, 1)
        },
        async postProposedJobs() {
            const response = await api.post('/proposed_jobs', {jobs: this.proposedJobs, candidate_id: this.candidate.id})
            if (response && response.data) {
                this.proposedJobs = response.data
            } 
        },
    },
    computed: {
        ...mapState('youthadvisor', [
            'professions'
        ])
    },
    async created() {
        this.retrieveProfessionsBySearch("")
        this.candidate = await this.retrieveCandidate(this.$route.params.id)
        this.proposedJobs = await this.retrieveProposedJobs(this.$route.params.id)
        this.proposedJobStatuses = await this.retrieveProposedJobStatuses()
        this.jobOffers = await this.retrieveJobOffers(this.$route.params.id)
    },
}
