import api from '@/services/api'
import router from '../../router'

const state = () => ({
    users: []
})

const getters = {
}

const mutations = {
    setUsers(state, users) {
        state.users = users
    },
    deleteUser(state, user) {
        const index = state.users.indexOf(user);
        if (index) state.users.splice(index, 1)
    },
    updateUser(state, user) {
        const index = state.users.indexOf(user);
        if (index) state.users[index] = user
    },
    addUser(state, user) {
        state.users.push(user)
    }
}

const actions = {
    async retrieveUsers({commit}) {
        const response = await api.get('/users')
        if (response && response.data) commit('setUsers', response.data)
    },
    async deleteUser({commit}, id) {
        const response = await api.delete(`/users/${id}`)
        if (response && response.data) commit('deleteUser', response.data)
    },
    async updateUser({commit}, user) {
        const response = await api.put(`/users/${user.id}`, user)
        if (response && response.data) {
            commit('updateUser', response.data)
            router.replace('/admin/dashboard').catch(()=>{})
        }
    },
    async createUser({commit}, user) {
        const response = await api.post('/users', user)
        if (response && response.data) {
            commit('addUser', response.data)
            router.replace('/admin/dashboard').catch(()=>{})
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}