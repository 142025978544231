import api from '@/services/api'
import { mapActions } from 'vuex'

export default {
    name: 'YouthAdvisorEdit',
    data() {
        return {
            candidate: {
                user: {
                    email: "",
                    first_name: "",
                    insertion: "",
                    last_name: ""
                }
            }
        }
    },
    methods: {
        ...mapActions('youthadvisor', [
            'updateCandidate'
        ]),
        async retrieveCandidate(id) {
            const response = await api.get(`/candidates/${id}`)
            if (response && response.data) return response.data
        },
        back() {
            this.$router.push({ name: 'Youth Advisor Detail', params: { id: this.$route.params.id } })
        },
    },
    computed: {
        isFormValid() {
            return this.candidate.user.email && this.candidate.user.first_name && this.candidate.user.last_name
        }
    },
    async created() {
        this.candidate = await this.retrieveCandidate(this.$route.params.id)
    },
}
