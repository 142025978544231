import Vue from 'vue'
import VueRouter from 'vue-router'

// Youth Advisor
import YouthAdvisor from '../components/YouthAdvisor/YouthAdvisor.vue'
import Dashboard from '../components/YouthAdvisor/Dashboard/Dashboard.vue'
import Account from '../components/YouthAdvisor/Account/Account.vue'
import YouthAdvisorDetail from '../components/YouthAdvisor/Detail/Detail.vue'
import YouthAdvisorNew from '../components/YouthAdvisor/New/New.vue'
import YouthAdvisorEdit from '../components/YouthAdvisor/Edit/Edit.vue'
import YouthAdvisorJobs from '../components/YouthAdvisor/Jobs/Jobs.vue'
import YouthAdvisorBusinesses from '../components/YouthAdvisor/Businesses/Businesses.vue'
import YouthAdvisorQuestionnaires from '../components/YouthAdvisor/Questionnaires/Questionnaires.vue'

// Admin
import Admin from '../components/Admin/Admin.vue'
import AdminAccount from '../components/Admin/Account/Account.vue'
import AdminDashboard from '../components/Admin/Dashboard/Dashboard.vue'
import AdminDetail from '../components/Admin/Detail/Detail.vue'
import AdminNewUser from '../components/Admin/NewUser/NewUser.vue'

// Candidate
import Candidate from '../components/Candidate/Candidate.vue'
import CandidateOnboarding from '../components/Candidate/Onboarding/Onboarding.vue'
import CandidateDashboard from '../components/Candidate/Dashboard/Dashboard.vue'
import CandidateQuestionnaire from '../components/Candidate/Questionnaire/Questionnaire.vue'
import CandidateQuestionnaires from '../components/Candidate/Questionnaires/Questionnaires.vue'
import CandidateAccount from '../components/Candidate/Account/Account.vue'
import ChangePassword from '../components/Candidate/ChangePassword/ChangePassword.vue'
import CandidateAvatar from '../components/Candidate/Avatar/Avatar.vue'
import CandidateJobOffers from '../components/Candidate/JobOffers/JobOffers.vue'
import Planning from '../components/Candidate/Planning/Planning.vue'
import JobOfferShow from '../components/Candidate/JobOfferShow/JobOfferShow.vue'
import HistoricalJobs from '../components/Candidate/HistoricalJobs/HistoricalJobs.vue'

// Business
import Business from '../components/Business/Business.vue'
import BusinessDashboard from '../components/Business/Dashboard/Dashboard.vue'
import BusinessQuestionnaire from '../components/Business/Questionnaire/Questionnaire.vue'
import BusinessAccount from '../components/Business/Account/Account.vue'
import BusinessAccountInfo from '../components/Business/AccountInfo/AccountInfo.vue'
import BusinessChangePassword from '../components/Business/ChangePassword/ChangePassword.vue'
import JobOffers from '../components/Business/JobOffers/JobOffers.vue'
import JobOfferNew from '../components/Business/JobOfferNew/JobOfferNew.vue'
import JobOfferEdit from '../components/Business/JobOfferEdit/JobOfferEdit.vue'
import Employees from '../components/Business/Employees/Employees.vue'
import EmployeeNew from '../components/Business/EmployeeNew/EmployeeNew.vue'
import AuthorizationRequests from '../components/Business/AuthorizationRequests/AuthorizationRequests.vue'

// Account manager
import AccountManager from '../components/AccountManager/AccountManager.vue'
import AccountManagerDashboard from '../components/AccountManager/Dashboard/Dashboard.vue'
import AccountManagerAccount from '../components/AccountManager/Account/Account.vue'
import AccountManagerJobOffers from '../components/AccountManager/JobOffers/JobOffers.vue'
import AccountManagerBusinesses from '../components/AccountManager/Businesses/Businesses.vue'
import EditBusiness from '../components/AccountManager/Edit/Edit.vue'
import NewBusiness from '../components/AccountManager/New/New.vue'
import ShowBusiness from '../components/AccountManager/Show/Show.vue'
import UpdatePassword from '../components/AccountManager/ChangePassword/ChangePassword.vue'

// General
import HomePage from '../components/HomePage/HomePage.vue'
import SignIn from '../components/SignIn/SignIn.vue'
import SignUp from '../components/SignUp/SignUp.vue'
import TermsAndConditions from '../components/TermsAndConditions/TermsAndConditions.vue'
import PrivacyPolicy from '../components/PrivacyPolicy/PrivacyPolicy.vue'
import Authentication from '../components/Authentication/Authentication.vue'
import Candidates from '../components/YouthAdvisor/Candidates/Candidates.vue'
import ForgotPassword from '../components/ForgotPassword/ForgotPassword.vue'
import ResetPassword from '../components/ResetPassword/ResetPassword.vue'
import TimeSlots from '../components/Business/TimeSlots/TimeSlots.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/jongerenadviseur',
    redirect: '/dashboard',
    name: 'YouthAdvisor',
    component: YouthAdvisor,
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
      },
      {
        path: '/kandidaten',
        name: 'Kandidaten',
        component: Candidates,
      },
      {
        path: '/account',
        name: 'Account',
        component: Account
      },
      {
        path: 'detail/:id',
        name: 'Youth Advisor Detail',
        component: YouthAdvisorDetail
      },
      {
        path: 'nieuw',
        name: 'Youth Advisor New',
        component: YouthAdvisorNew
      },
      {
        path: 'edit/:id',
        name: 'Youth Advisor Edit',
        component: YouthAdvisorEdit
      },
      {
        path: 'jobs/:id',
        name: 'Youth Advisor Jobs',
        component: YouthAdvisorJobs
      },
      {
        path: 'businesses/:id',
        name: 'Youth Advisor Businesses',
        component: YouthAdvisorBusinesses
      },
      {
        path: 'vragenlijsten/:id',
        name: 'Youth Advisor Questionnaires',
        component: YouthAdvisorQuestionnaires
      },
    ]
  },
  {
    path: '/kandidaat',
    name: 'Candidate',
    component: Candidate,
    children: [
      {
        path: 'onboarding',
        name: 'Candidate Onboarding',
        component: CandidateOnboarding
      },
      {
        path: '/kandidaat',
        name: 'Candidate Dashboard',
        component: CandidateDashboard
      },
      {
        path: 'vragenlijsts',
        name: 'Candidate Questionnaires',
        component: CandidateQuestionnaires
      },
      {
        path: 'vragenlijst/:id',
        name: 'Candidate Questionnaire',
        component: CandidateQuestionnaire
      },
      {
        path: 'account',
        name: 'Candidate Account',
        component: CandidateAccount
      },
      {
        path: 'account/change-password',
        name: 'ChangePassword',
        component: ChangePassword
      },
      {
        path: 'account/avatar',
        name: 'Candidate Avatar',
        component: CandidateAvatar
      },
      {
        path: 'job-offers',
        name: 'CandidateJobOffers',
        component: CandidateJobOffers
      },
      {
        path: 'job-offers/:id',
        name: 'Job Offer Show',
        component: JobOfferShow
      },
      {
        path: 'planning',
        name: 'Candidate Planning',
        component: Planning
      },
      {
        path: 'account/historical-jobs',
        name: 'HistoricalJobs',
        component: HistoricalJobs
      },
    ]
  },
  {
    path: '/bedrijf',
    name: 'Business',
    component: Business,
    children: [
      {
        path: '/bedrijf',
        name: 'Business Dashboard',
        component: BusinessDashboard
      },
      {
        path: 'vragenlijst',
        name: 'Business Questionnaire',
        component: BusinessQuestionnaire
      },
      {
        path: 'account',
        name: 'Business Account',
        component: BusinessAccount
      },
      {
        path: 'account/change-password',
        name: 'Business Change Password',
        component: BusinessChangePassword
      },
      {
        path: 'account/bedrijf-info',
        name: 'Business Account Info',
        component: BusinessAccountInfo
      },
      {
        path: 'job-offers',
        name: 'JobOffers',
        component: JobOffers
      },
      {
        path: 'open-timeslots',
        name: 'TimeSlots',
        component: TimeSlots
      },
      {
        path: 'employees',
        name: 'Employees',
        component: Employees
      },
      {
        path: 'employee-new/:id',
        name: 'EmployeeNew',
        component: EmployeeNew
      },
      {
        path: 'job-offer/new',
        name: 'JobOfferNew',
        component: JobOfferNew
      },
      {
        path: 'job-offer/edit/:id',
        name: 'JobOfferEdit',
        component: JobOfferEdit
      },
      {
        path: 'authorization-requests',
        name: 'AuthorizationRequests',
        component: AuthorizationRequests
      }
    ]
  },
  {
    path: '/account-manager',
    redirect: '/account-manager',
    name: 'AccountManager',
    component: AccountManager,
    children: [
      {
        path: '/account-manager',
        name: 'Account Manager Dashboard',
        component: AccountManagerDashboard
      },
      {
        path: 'account',
        name: 'Account Manager Account',
        component: AccountManagerAccount
      },
      {
        path: 'account/change-password',
        name: 'UpdatePassword',
        component: UpdatePassword
      },
      {
        path: 'bedrijven',
        name: 'Businesses',
        component: AccountManagerBusinesses
      },
      {
        path: 'edit/:id',
        name: 'Edit Business',
        component: EditBusiness
      },
      {
        path: 'nieuw',
        name: 'New Business',
        component: NewBusiness
      },
      {
        path: 'show/:id',
        name: 'Show Business',
        component: ShowBusiness
      },
      {
        path: 'job-offers',
        name: 'Account Manager Job Offers',
        component: AccountManagerJobOffers
      }
    ]
  },
  {
    path: '/admin',
    redirect: '/admin/dashboard',
    name: 'Admin',
    component: Admin,
    children: [
      {
        path: 'account',
        name: 'Admin Account',
        component: AdminAccount
      },
      {
        path: 'dashboard',
        name: 'Admin Dashboard',
        component: AdminDashboard
      },
      {
        path: 'detail/:id',
        name: 'Admin Detail',
        component: AdminDetail
      },
      {
        path: 'nieuw',
        name: 'Admin New',
        component: AdminNewUser
      },
    ]
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: TermsAndConditions
  },
  {
    path: '/login',
    name: 'SignIn',
    component: SignIn
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp
  },
  {
    path: '/forgot_password',
    name: 'ForgotPassword',
    component: ForgotPassword
  },
  {
    path: '/reset_password.:token',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/rol',
    name: 'Authentication',
    component: Authentication
  },
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
