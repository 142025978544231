import api from '@/services/api'

export default {
    name: 'YouthAdvisorBusinesses',
    data() {
        return {
            candidate: {},
            businesses: [],
        }
    },
    methods: {
        async retrieveCandidate(id) {
            const response = await api.get(`/candidates/${id}`)
            if (response && response.data) return response.data
        },
        async retrieveBusinessScores(candidateId) {
            const response = await api.get(`/business_scores/${candidateId}`)
            if (response && response.data) return response.data
        },
        back() {
            this.$router.push({ name: 'Youth Advisor Detail', params: { id: this.$route.params.id } })
        }
    },
    async created() {
        this.candidate = await this.retrieveCandidate(this.$route.params.id)
        this.businesses = await this.retrieveBusinessScores(this.$route.params.id)
    }
}
