import api from "@/services/api";
import Vue from "vue"
import VueMeetingSelector from "vue-meeting-selector";
import moment from 'moment';
import 'moment/locale/nl'  // without this line it didn't work
moment.locale('nl')
// import data from "./data.json";

export default {
  name: "JobOfferEdit",
  components: {
    VueMeetingSelector,
  },
  data() {
    return {
      jobOffer: {
        description: "",
        name: "",
      },
      showLocation: false,
      timeSlots: {},
      date: new Date(),
      meetingsDays: [],
      meeting: { date: "No Date Selected" },
      loading: true,
      nbDaysToDisplay: 7,
      timeSlot: {
        available_slots: "",
        slot: "",
        custom_slot: "",
        custom_end_time: "",
      },
      candidates: {},
      isHidden: false,
      enableWhatsappBtn: null,
      whatsappBtnText: 'Deel via Whatsapp',
      locale: 'nl',
      labels: {
          nl: {
              labelHours: 'Uur',
              labelMinutes: 'minuten',
              labelSeconds: 'seconden',
              labelIncrement: 'Toename',
              labelDecrement: 'Verminderen',
              labelSelected: 'Geselecteerde tijd',
              labelNoTimeSelected: 'Geen tijd geselecteerd',
              labelCloseButton: 'Sluiten'
          }
      }
    };
  },
  methods: {
    async updateJobOffer() {
      const response = await api.put(
        `/job_offers/${this.jobOffer.id}`,
        this.jobOffer
      );
      if (response && response.data) {
        this.$router.push({ name: 'JobOfferEdit', params: { id: response.data.id } })
      }
    },
    async retrieveJobOffer(id) {
      const response = await api.get(`/job_offers/${id}`);
      if (response && response.data) return response.data;
    },
    add(id) {
      this.$router.replace({ name: 'EmployeeNew', params: {id: id }})
    },
    async retrieveTimeSlots(id) {
      const response = await api.get(`/job_offers/${id}/time_slots`);
      if (response && response.data) return response.data;
    },
    updateSingleTimeSlot(timeSlot) {
      const dateKeys = Object.keys(this.timeSlots);
      const targetDateKey = dateKeys.find(dateKey => this.timeSlots[dateKey].some(ts => ts.id === timeSlot.id));
    
      if (targetDateKey) {
        const targetTimeSlotIndex = this.timeSlots[targetDateKey].findIndex(ts => ts.id === timeSlot.id);
    
        if (targetTimeSlotIndex !== -1) {
          this.$set(this.timeSlots[targetDateKey], targetTimeSlotIndex, timeSlot);
        }
      } else {
        // No target date key found, create a new date key and push the timeSlot
        const newDateKey = timeSlot.slot.split('T')[0];
    
        if (this.timeSlots[newDateKey]) {
          // Date key exists, append the timeSlot to the existing array
          this.timeSlots[newDateKey].push(timeSlot);
        } else {
          // Date key doesn't exist, create a new array with the timeSlot
          const newIndex = dateKeys.findIndex(dateKey => dateKey > newDateKey);
          if (newIndex !== -1) {
            // Insert the new date key at the appropriate position
            const updatedTimeSlots = {};
            for (let i = 0; i < dateKeys.length; i++) {
              if (i === newIndex) {
                updatedTimeSlots[newDateKey] = [timeSlot];
              }
              updatedTimeSlots[dateKeys[i]] = this.timeSlots[dateKeys[i]];
            }
            this.timeSlots = updatedTimeSlots;
          } else {
            // Append the new date key at the end
            this.$set(this.timeSlots, newDateKey, [timeSlot]);
          }
        }
      }

      this.timeSlot.custom_slot = null
      this.timeSlot.custom_end_time = null
      this.timeSlot.available_slots = null
      this.hideModal2()
    },
    async retrieveMeetingDays(id) {
      const response = await api.get(`/job_offers/${id}/generate_time_slots?date=${this.date}`);
      if (response && response.data) return response.data;
    },
    // @click on button-right
    async nextDate() {
      const newDate = this.date;
      newDate.setDate(newDate.getDate() + 7)
      this.date = newDate;
      this.meetingsDays = await this.retrieveMeetingDays(this.$route.params.id);
    },
    // @click on button-left
    async previousDate() {
      const newDate = this.date;
      newDate.setDate(newDate.getDate() - 7)
      this.date = newDate;
      this.meetingsDays = await this.retrieveMeetingDays(this.$route.params.id);
    },
    // to create the time slot
    async createTimeSlot() {
      this.timeSlot.job_offer_id = this.jobOffer.id
      this.timeSlot.slot = this.meeting['date']
      const response = await api.post(`/time_slots`, this.timeSlot)
      if (response) this.updateSingleTimeSlot(response)
    },
    async updateTimeSlot(timeSlot) {
      const response = await api.put(`/time_slots/${timeSlot.id}`, this.timeSlot)
      if (response) {
        this.updateSingleTimeSlot(response)
        this.hideModal()
      }
    },
    async deleteTimeSlot(timeSlot) {
      if(confirm("Wilt u permanent verwijderen?")) {
        const response = await api.delete(`/time_slots/${timeSlot.id}`)
        if (response && response.data) {
          this.timeSlots = await this.retrieveTimeSlots(this.$route.params.id);
          // this.timeSlots.delete(timeSlot)
          this.hideModal()
        }
      }
    },
    async showModal(event) {
      this.$refs['modal-1'].show()
      const id = event.target.id
      this.isHidden = false
      const response = await api.get(`/time_slots/${id}`);
      if (response & response.id) return response;
      this.timeSlot = response;
      await this.getCandidates(id)
    },
    hideModal() {
      this.$refs['modal-1'].hide()
    },
    hideModal2() {
      this.$refs['modal-2'].hide()
    },
    async editBookings(id) {
      this.isHidden = true
      await this.getAllCandidates(id)
    },
    async hideEditBookings(id) {
      this.isHidden = false
      await this.getCandidates(id)
    },
    async getCandidates(id) {
      const response = await api.get(`/time_slots/${id}/candidates`)
      this.candidates = response;
    },
    async getAllCandidates(id) {
      const response = await api.get(`/time_slots/${id}/all_candidates`)
      this.candidates = response;
    },
    async addToSlot(id, candidate_id, booking_id) {
      const response = await api.post(`/time_slots/${id}/create_business_booking`, {candidate_id: candidate_id, booking_id: booking_id})
      if (response) {
        this.timeSlot = response.time_slot
        this.candidates = this.getAllCandidates(response.time_slot.id)
        this.updateSingleTimeSlot(response.time_slot)
      }
    },
    async removeFromSlot(id, candidate_id) {
      const response = await api.post(`/time_slots/${id}/remove_business_booking`, {candidate_id: candidate_id})
      if (response) {
        this.timeSlot = response
        this.candidates = this.getAllCandidates(response.id)
        this.updateSingleTimeSlot(response)
      }
    },
    async deactivateVacancy() {
      if(confirm("Wil je echt deactiveren?")) {
        const response = await api.get(`/job_offers/${this.jobOffer.id}/deactivate`)
        if (response && response.data) return response.data;
      }
    },
    translateDays() {
      const titles = document.querySelectorAll('.day__title');
      titles.forEach((title) => {
        const originalText = title.textContent.trim(); // Get the original text content
        const translatedText = this.$t(originalText, 'nl'); // Translate the text content to Dutch using Vue.js $t method
        title.textContent = translatedText; // Update the text content with the translated text
      });
    },
    enableWhatsAppNotification() {
      if (this.jobOffer.whatsapp_next_sent_at == null) {
        return false
      } else if (new Date(this.jobOffer.whatsapp_next_sent_at) < new Date() ) {
        return false
      } else {
        return true 
      }
      
    },
    async sendWhatsAppNotification() {
      if(confirm("Weet je het zeker? je kunt dit maar één keer per week doen")) {
        this.whatsappBtnText = 'bezig met laden...'
        const response = await api.get(`/job_offers/${this.jobOffer.id}/send_whatsapp_notification`)
        if (response && response.status == 200) {
          this.enableWhatsappBtn = true
          this.whatsappBtnText = 'Deel via Whatsapp'
          Vue.notify({
            group: 'data',
            title: 'Fout opgetreden',
            text: `${response.success}`,
            type: 'success'
        });
          return response
        } else {
          this.whatsappBtnText = 'Deel via Whatsapp'
          return response
        }
      }
    }
  },
  computed: {
    isFormValid() {
      return this.jobOffer.name && this.jobOffer.description;
    },
    isEmpty() {
      return Object.keys(this.timeSlots).length === 0;
    },
    hasCandidates() {
      return Object.keys(this.candidates).length === 0;
    },
    classNames() {
      return {
        tabLoading: 'loading-div',
      };
    },
  },
  filters: {
    day: function (date) {
      return moment(date).format('dddd');
    },
    date: function (date) {
      return moment(date).format('DD MMM')
    },
    time: function (date) {
      return moment(date).format('HH:mm')
    },
    fullDate: function (date) {
      return moment(date).format('dddd DD MMMM')
    }
  },
  async created() {
    this.jobOffer = await this.retrieveJobOffer(this.$route.params.id);
    this.timeSlots = await this.retrieveTimeSlots(this.$route.params.id);
    this.meetingsDays = await this.retrieveMeetingDays(this.$route.params.id);
    this.date = new Date();
    this.loading = false;
    this.enableWhatsappBtn = this.enableWhatsAppNotification()
    this.translateDays()
  },
  updated() {
    this.translateDays()
  }
};
