import api from '@/services/api'
import { mapActions } from 'vuex'

export default {
    name: 'AdminDetail',
    data() {
        return {
            user: {
                email: "",
                first_name: "",
                insertion: "",
                last_name: "",
                roles_list: []
            }
        }
    },
    methods: {
        ...mapActions('admin', [
            'updateUser'
        ]),
        async retrieveUser(id) {
            const response = await api.get(`/users/${id}`)
            if (response && response.data) return response.data
        }
    },
    computed: {
        isFormValid() {
            return this.user.email
        }
    },
    async created() {
        this.user = await this.retrieveUser(this.$route.params.id)
    },
}
