import api from '@/services/api'
import AccountTogglePills from '../AccountTogglePills/AccountTogglePills.vue'
import { mapActions } from "vuex"

export default {
    name: 'CandidateAccount',
    components: {
        AccountTogglePills
    },
    data() {
        return {
            candidate: {
                user: {
                    email: "",
                    first_name: "",
                    insertion: "",
                    last_name: "",
                    birthday: ""
                }
            }
        }
    },
    methods: {
        ...mapActions('authentication', [
            'signOut'
        ]),
        async retrieveCandidate() {
            const response = await api.get(`/current_candidate`)
            if (response && response.data) return response.data
        },
        async updateCandidate(candidate) {
            const response = await api.put(`/candidates/${candidate.id}`, candidate)
            if (response && response.data) {
                this.candidate = response.data
                // this.$swal('Details succesvol bijgewerkt');
            }
        },
        back() {
            this.$router.push({ name: 'CandidateDashboard' })
        },
        goToChangePassword() {
            this.$router.push({ name: 'ChangePassword' })
        },
    },
    computed: {
        isFormValid() {
            return this.candidate.user.email && this.candidate.user.first_name && this.candidate.user.last_name
        },
        reversedBirthday(){
            if (this.candidate.user.birthday === null) {
                return null
            } else {
                const birthday = this.candidate.user.birthday.split('-').reverse().join('-');
                this.candidate.user.birthday = birthday
                return birthday
            }
        }
    },
    async created() {
        this.candidate = await this.retrieveCandidate()
    },
}
