import Header from './Header/Header.vue'
import { mapActions } from 'vuex'

export default {
    name: 'Admin',
    components: {
        Header
    },
    methods: {
        ...mapActions('authentication', [
            'retrieveSignedInStatus',
        ]),
    },
    created() {
        this.retrieveSignedInStatus()
    },
    updated() {
        this.retrieveSignedInStatus()
    },
}