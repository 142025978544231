import { store } from "../store"
import Vue from "vue"

export default {
    get(url) {
        return this.apiCall(url, 'GET')
    },
    
    post(url, data, tokenRefresh=true) {
        return this.apiCall(url, 'POST', data, tokenRefresh)
    },

    put(url, data) {
        return this.apiCall(url, 'PUT', data)
    },

    delete(url, data) {
        return this.apiCall(url, 'DELETE', data)
    },

    url() {
        return process.env.VUE_APP_API_URL
    },

    // Token refresh is only false on the signout post. Else the refreshToken function becomes recursive
    async apiCall(url, method, data, tokenRefresh=true) {
        var headers = new Headers();
        headers.append("X-CSRF-Token", localStorage.getItem('csrf'));
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        
        try {
            const response = await (await fetch(this.url() + url, {
                method: method,
                credentials: "include",
                headers: headers,
                body: JSON.stringify(data)
            })).json()
            if (response.status == 401) {
                // Needs testing
                if (tokenRefresh) {
                    await store.dispatch('authentication/refreshToken', { root:true })
                    return await this.apiCall(url, method, data, false);
                }
                return {error: "Not able to refresh token"}
            } else if (response.error) {
                Vue.notify({
                    group: 'data',
                    title: 'Fout opgetreden',
                    text: `${response.error}`,
                    type: 'error'
                });
            } else {
                return response
            }
        } catch (error) {
            Vue.notify({
                group: 'data',
                title: 'Fout opgetreden',
                text: `${error}`,
                type: 'error'
            });
            return {error: error}
        }
    }
}
