import api from '@/services/api'

export default {
    name: 'ShowBusiness',
    data() {
        return {
            business: {
                name: "",
                kvk_nummer: "",
                users: []
            },
            jobOffers: []
        }
    },
    methods: {
        async retrieveBusiness(id) {
            const response = await api.get(`/businesses/${id}`)
            if (response && response.data) return response.data
        },
        edit(id) {
            this.$router.push({ name: 'Edit Business', params: { id: id } })
        },
        back() {
            this.$router.push({ name: 'Businesses' })
          },
        async retrieveJobOffers(id) {
            const response = await api.get(`/business_job_offers/${id}`)
            if (response && response.data) return response.data
        },
        goToJobOffer(id){
            this.$router.push({ name: 'JobOfferEdit', params: { id: id } })
        },
        add(id) {
            this.$router.push({ name: 'JobOfferNew', params: {id: id}})
        },
        async deactivateVacancy(event) {
            const id = event.target.id
            if(confirm("Wil je echt deactiveren?")) {
              const response = await api.get(`/job_offers/${id}/deactivate`)
              if (response && response.data) {
                  this.jobOffers = await this.retrieveJobOffers(this.business.id)
              }
            }
        },
    },
    async created() {
        this.business = await this.retrieveBusiness(this.$route.params.id)
        this.jobOffers = await this.retrieveJobOffers(this.business.id)
    }
}
