import api from "@/services/api";
export default {
    name: 'Dashboard',
    data() {
        return {
            dashboardData: {}
        }
    },
    methods: {
        goToAuthorizationRequests() {
            this.$router.push({ name: 'AuthorizationRequests' })
        },
        goToOpenTimeSlots() {
            this.$router.push({ name: 'TimeSlots' })
        },
        goToEmployees() {
            this.$router.push({ name: 'Employees' })
        },
        async retrieveDashboardData() {
            const response = await api.get('/account_manager/dashboard_data')
            if (response) return response
        }
    },
    async created() {
        this.dashboardData = await this.retrieveDashboardData()
    }
}
