import { mapState, mapActions } from "vuex"

export default {
    name: 'Candidates',
    computed: {
        ...mapState('youthadvisor', [
            'candidates'
        ])
    },
    methods: {
        ...mapActions('youthadvisor', [
            'retrieveCandidates',
            'deleteCandidate'
        ]),
        add() {
            this.$router.push({ name: 'Youth Advisor New'})
        },
        detail(id) {
            this.$router.push({ name: 'Youth Advisor Detail', params: { id: id } })
        },
    },
    created() {
        this.retrieveCandidates()
    }
}
