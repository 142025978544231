import api from '@/services/api'
import { mapActions } from "vuex"

export default {
    name: 'ChangePassword',
    data() {
        return {
            user: {
                first_name: "",
                current_password: "",
                new_password: "",
                password_confirmation: "",
                avatar: null
            }
        }
    },
    methods: {
        ...mapActions('authentication', [
            'signOut'
        ]),
        async retrieveUser() {
            const response = await api.get(`/current_user`)
            if (response && response.data) return response.data
        },
        async updateUserPassword(user) {
            const response = await api.put(`/users/${user.id}/change_password`, user)
            if (response) {
                this.signOut();
                // this.$swal('Password updated');
            }

        },
        goToDashboard() {
            this.$router.push({name: 'Business Account'})
        },
        goToPasswordChange() {
            this.$router.push({name: 'Business Change Password' })
        },
        goToBusinessInfo() {
            this.$router.push({name: 'Business Account Info'})
        }
    },
    computed: {
        isFormValid() {
            return this.user.current_password && this.user.new_password && this.user.password_confirmation
        }
    },
    async created() {
        this.user = await this.retrieveUser()
    },
}
