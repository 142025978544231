import { mapActions } from "vuex"

export default {
    name: 'NewCandidate',
    data() {
        return {
            candidate: {
                email: "",
                password: "",
                first_name: "",
                insertion: "",
                last_name: ""
            }
        }
    },
    methods: {
        ...mapActions('youthadvisor', [
            'createCandidate'
        ]),
        back() {
            this.$router.push({ name: 'Dashboard' })
        },
    },
    computed: {
        isFormValid() {
            return this.candidate.email && this.candidate.password && this.candidate.first_name && this.candidate.last_name
        }
    }
}
