import api from '@/services/api'
import router from '../../router'

const state = () => ({
    candidates: [],
    professions: []
})

const getters = {
}

const mutations = {
    setCandidates(state, candidates) {
        state.candidates = candidates
    },
    deleteCandidate(state, candidate) {
        const index = state.candidates.findIndex(c => c.id == candidate.id);
        if (index != -1) state.candidates.splice(index, 1)
    },
    updateCandidate(state, candidate) {
        const index = state.candidates.indexOf(candidate);
        if (index) state.candidates[index] = candidate
    },
    addCandidate(state, candidate) {
        state.candidates.push(candidate)
    },
    setProfessions(state, professions) {
        state.professions = professions
    }
}

const actions = {
    async retrieveCandidates({commit}) {
        const response = await api.get('/candidates')
        if (response && response.data) commit('setCandidates', response.data)
    },
    async deleteCandidate({commit}, id) {
        const response = await api.delete(`/candidates/${id}`)
        if (response && response.data) commit('deleteCandidate', response.data)
    },
    async updateCandidate({commit}, candidate) {
        const response = await api.put(`/candidates/${candidate.id}`, candidate)
        if (response && response.data) {
            commit('updateCandidate', response.data)
            router.push({ name: 'Youth Advisor Detail', params: { id: response.data.id } })
        }
    },
    async createCandidate({commit}, candidate) {
        const response = await api.post('/candidates', candidate)
        if (response && response.data) {
            commit('addCandidate', response.data)
            router.push({ name: 'Dashboard' })
        }
    },
    async retrieveProfessionsBySearch({commit}, search) {
        const response = await api.post('/professions', {search: search})
        if (response && response.data) commit('setProfessions', response.data)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}