import api from '@/services/api'
import { mapActions } from 'vuex'

export default {
    name: 'EditBusiness',
    data() {
        return {
            business: {
                id: "",
                name: "",
                kvk_nummer: "",
                director: {},
                hr: {},
                operations: {},
            },
            toggle: false,
            toggle_two: false
        }
    },
    methods: {
        ...mapActions('accountmanager', [
            'updateBusiness'
        ]),
        async retrieveBusiness(id) {
            const response = await api.get(`/businesses/${id}`)
            if (response && response.data) {
                const businessData = response.data;
                const director = businessData.users[0] || {};
                const hr = businessData.users[1] || {};
                const operations = businessData.users[2] || {};

             return {
                id: businessData.id,
                name: businessData.name,
                kvk_nummer: businessData.kvk_nummer,
                director: director,
                hr: hr,
                operations: operations
             }
            }
        },
        back() {
            this.$router.push({ name: 'Show Business', params: { id: this.business.id } })
        },
        showSecondaryForm() {
            this.toggle = !this.toggle
            if(this.toggle) {
                this.$nextTick(() => {
                    this.$refs["hr"].scrollIntoView({ behavior: "smooth" })
                })
            }
          },
        showTertiaryForm() {
            this.toggle_two = !this.toggle_two
            if(this.toggle_two) {
                this.$nextTick(() => {
                    this.$refs["manager"].scrollIntoView({ behavior: "smooth" })
                })
            }
        },
    },
    computed: {
        isFormValid() {
            // return this.business.user.email && this.business.user.first_name && this.business.user.last_name
            return true;
        }
    },
    async created() {
        this.business = await this.retrieveBusiness(this.$route.params.id)
        console.log(this.business)
    },
}
