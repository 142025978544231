import api from "@/services/api";
import moment from 'moment';
import 'moment/locale/es'  // without this line it didn't work
moment.locale('nl')
import TogglePills from '../TogglePills/TogglePills.vue'

export default {
  name: 'JobOfferShow',
  components: {
    TogglePills
},
  data() {
    return {
        jobOffer: {
            description: "",
            name: "",
            hourly_rate: "",
        },
        timeSlots: {},
        timeSlot: {},
        bookedSlot: {},
        bookedSlots: {},
        toggle: false,
        toggleLocation: false,
        selectedTimeslot: null,
        authorizationStatus: {
          status: ""
        },
        isAuthorized: false,
        isLoading: false
    }
},
methods: {
    async retrieveJobOffer(id) {
      this.isLoading = true
       const response = await api.get(`/job_offers/${id}`);
        if (response && response.data) {
          this.isLoading = false
          return response.data;
        }
    },
    async retrieveTimeSlots(id) {
      this.isLoading = true
        const response = await api.get(`/job_offers/${id}/time_slots`);
        if (response && response.data) {
          this.isLoading = false
          return response.data;
        }
      },
    selectTimeslot(timeslot) {
        this.selectedTimeslot = timeslot;
    },
    async showModal(event) {
      this.$refs['modal-1'].show()
      const id = event.target.id
      const response = await api.get(`/time_slots/${id}`);
      if (response & response.id) return response;
      this.timeSlot = response;
    },
    hideModal() {
      this.$refs['modal-1'].hide()
    },
    async showEditModal(event) {
      this.$refs['modal-2'].show()
      const id = event.target.id
      const response = await api.get(`/time_slots/${id}`);
      if (response & response.id) return response;
      this.timeSlot = response;
    },
    async showReserveModal(event) {
      this.$refs['modal-3'].show()
      const id = event.target.id
      const response = await api.get(`/time_slots/${id}`);
      if (response & response.id) return response;
      this.timeSlot = response;
    },
    showCancelForm() {
      this.toggle = !this.toggle
    },
    showLocation() {
      this.toggleLocation = !this.toggleLocation
    },
    async createBooking(event) {
      const id = event.target.id
      const response = await api.post(`/time_slots/${id}/create_booking`)
      this.bookedSlots = await this.retrieveBookedSlots(this.$route.params.id);
      this.selectedTimeslot = response.time_slot
      this.hideModal();
    },
    async retrieveBookedSlots(id){
      const response = await api.get(`/job_offers/${id}/booked_timeslots`);
      if (response && response.data) return response.data;
      this.bookedSlots = response.data;
    }, 
    async cancelBooking() {
      const response = await api.put(`/time_slot_bookings/${this.timeSlot.id}/cancel_booking`, this.bookedSlot)
      if (response.id) this.bookedSlots = await this.retrieveBookedSlots(this.$route.params.id);
      this.timeSlots = await this.retrieveTimeSlots(this.$route.params.id);
      this.$refs['modal-2'].hide()
    },
    async retriveAuthorizationStatus(id) {
      const response = await api.get(`/job_offers/${id}/authorization_status`)
      return response && response.data ? response.data : {status: ""};
    },
    async createAuthorizationRequest(id) {
      const response = await api.get(`/job_offers/${id}/authorization_request`);
      if (response && response.data) {
        this.authorizationStatus = response.data;
        return response.data;
      }
    }
  },
  computed: {
    isEmpty() {
      return Object.keys(this.timeSlots).length === 0;
    },
    isDeactivated() {
       return this.jobOffer.status === 'Inactive'
    },
    noBookedSlots() {
      return Object.keys(this.bookedSlots).length === 0;
    }
  },
  filters: {
    day: function (date) {
      return moment(date).format('dddd');
    },
    date: function (date) {
      return moment(date).format('DD MMM')
    },
    time: function (date) {
      return moment(date).format('HH:mm')
    },
    fullDate: function (date) {
      return moment(date).format('dddd DD MMMM')
    }
  },
  async created() {
    this.jobOffer = await this.retrieveJobOffer(this.$route.params.id);
    this.timeSlots = await this.retrieveTimeSlots(this.$route.params.id);
    this.bookedSlots = await this.retrieveBookedSlots(this.$route.params.id);
    this.authorizationStatus = await this.retriveAuthorizationStatus(this.$route.params.id)
    this.isAuthorized = this.authorizationStatus.status === 'Authorized';
    this.loading = false;
  },
}