import api from "@/services/api"
export default {
    name: 'CandidateQuestionnaires',
    data() {
        return {
            questionnaires: {},
        }
    },
    methods: {
        async getQuestionnaires() {
            const response = await api.get(`/candidate_questionnaires`)
             if (response && response.data) return response.data;
         },
         goToQuestionnaire(id) {
            this.$router.push({ name: 'Candidate Questionnaire', params: { id: id } })
         },
         redirectToDashboard() {
            if (this.questionnaires.incompleet.length === 0) {
                this.$router.push({ name: 'Candidate Dashboard'})
            }
         }
    },
    async created() {
        this.questionnaires = await this.getQuestionnaires()
        this.redirectToDashboard()
    },
}