import { mapState, mapActions } from "vuex"
import api from "@/services/api";

export default {
    name: 'Businesses',
    data() {
        return {
            accountManager: {}
        }
    },
    computed: {
        ...mapState('accountmanager', [
            'businesses'
        ])
    },
    methods: {
        ...mapActions('accountmanager', [
            'retrieveBusinesses',
            'deleteBusiness'
        ]),
        add() {
            this.$router.push({ name: 'New Business'})
        },
        edit(id) {
            this.$router.push({ name: 'Edit Business', params: { id: id } })
        },
        show(id) {
            this.$router.push({ name: 'Show Business', params: { id: id } })
        },
        handleClick(id) {
            if (this.accountManager.business_privilege == true) {
                this.show(id)
            } else {
                this.edit(id)
            }
        },
        send(business) {
            console.log("Verzenden van analyse naar bedrijf")
            console.log(business)
        },
        async retrieveAccountManager() {
            const response = await api.get(`/current_account_manager`)
            if (response && response.data) return response.data;
        },
        goToDashboard() {
            this.$router.push({ name: 'Account Manager Dashboard'})
        }
    },
    async created() {
        this.accountManager = await this.retrieveAccountManager()
        this.retrieveBusinesses()
    }
}
