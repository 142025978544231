export default {
    name: 'AccountTogglePills',
    props: {
        activePill: String,
    },
    methods: {
        goToDashboard() {
            this.$router.push({name: 'Candidate Account'})
        },
        goToPasswordChange() {
            this.$router.push({name: 'ChangePassword' })
        },
        goToAvatar() {
            this.$router.push({name: 'Candidate Avatar' })
        }
    }
}