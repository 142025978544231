export default {
    name: 'Signin',
    methods: {
        goToSignIn() {
            this.$router.replace({path: '/login'})
        },
        goToSignUp() {
            this.$router.replace({path: '/signup'})
        }
    }
}