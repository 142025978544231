import api from "@/services/api"
export default {
    name: 'ForgotPassword',
    data () {
      return {
        email: '',
        showMessage: false
      }
    },
    methods: {
        async submitForm() {
            const response = await api.put(`/forgot_password`, {email: this.email})
            if (response && response.message) {
                this.showMessage = true
            }
        },
        goToHomePage() {
            this.$router.push({ name: 'HomePage' })
        },
    }
}