import { mapActions } from "vuex"

export default {
    name: 'AdminAccount',
    methods: {
        ...mapActions('authentication', [
            'signOut'
        ]),
    }
}
