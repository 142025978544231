import api from '@/services/api'
export default {
    name: 'Header',
    data() {
        return {
            user: {
                first_name: "",
                avatar: null
            }
        }
    },
    methods: {
        async retrieveCandidate() {
            const response = await api.get(`/current_user`)
            if (response && response.data) return response.data
        },
        goToProfile() {
            this.$router.push({name: 'Candidate Account'})
        },
        goToDashboard() {
            this.$router.replace({path: '/kandidaat'})
        }
    },
    async created() {
        this.user = await this.retrieveCandidate()
    }
}
