import api from "@/services/api"
export default {
    name: 'CandidateOnboardingDashboard',
    data() {
        return {
            dashboardData: {},
            isLoading: false,
            motivationQuestionnaire: {}
        }
    },
    methods: {
        async retrieveDashboardData() {
            this.isLoading = true
            const response =  await api.get('/candidate/dashboard')
            if (response) {
                this.isLoading = false
                return response
            }
        },
        goToHistoricalJobs() {
            this.$router.push({name: 'HistoricalJobs'})
        },
        goToQuestionnaires() {
            this.$router.push({name: 'Candidate Questionnaires'})
        },
        toggleModal() {
            this.$refs['modal-1'].toggle()
        },
        goToMotivationQuestionnaire() {
            this.$router.replace(`/kandidaat/vragenlijst/${this.motivationQuestionnaire.id}`)
        },
        async retrieveMotivationQuestionnaire() {
            const response = await api.get('/candidate/motivation_questionnaire')
            if (response) return response
        },
    },
    async created() {
        this.dashboardData = await this.retrieveDashboardData()
        this.motivationQuestionnaire = await this.retrieveMotivationQuestionnaire()
    }
}